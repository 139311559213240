import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: "AIzaSyD2JL8lQev8zIKX_vUK5Aa9262hiqyKhTM",
  authDomain: "kswipe-e01ea.firebaseapp.com",
  databaseURL: "https://kswipe-e01ea.firebaseio.com",
  projectId: "kswipe-e01ea",
  storageBucket: "kswipe-e01ea.appspot.com",
  messagingSenderId: "903119829638",
  appId: "1:903119829638:web:3ceffcd42b20f131e92f07",
  measurementId: "G-H5FM7L1Q2Z"
};

firebase.initializeApp(config)
